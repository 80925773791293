<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel title="ลูกค้าที่ไม่มีประวัติการซื้อ (Prospect)" :filter="filter" @search="handleSearch"
        @clear="clearFilter">
        <template v-slot:filter-option>
          <b-col>
            <InputText v-model="filter.telephone" textFloat="เบอร์โทรศัพท์ (Telephone)"
              placeholder="เบอร์โทรศัพท์ (Telephone)" type="text" name="telephone" className="mb-2"
              @onEnter="handleSearch" />
          </b-col>
          <b-col>
            <InputText v-model="filter.name" textFloat="ชื่อสมาชิก (Member Name)" placeholder="ชื่อสมาชิก (Member Name)"
              type="text" name="name" className="mb-2" @onEnter="handleSearch" />
          </b-col>
          <b-col>
            <InputSelect title="เพศ (Gender)" isRequired :options="genderList" v-model="filter.gender"
              @onDataChange="(val) => (filter.gender = val)" textField="name" valueField="id" class="mb-2" />
          </b-col>
          <b-col>
            <InputDatePickerFilter textFloat="วันที่ลงทะเบียน (Date From)" name="dateFrom" ref="dateFrom"
              v-model="filter.dateFrom" @input="changeDateFrom" :v="$v.filter.dateFrom" placeholder="DD/MM/YYYY"
              class="mb-2" />
          </b-col>
          <b-col>
            <InputDatePickerFilter textFloat="วันที่ลงทะเบียน (Date To)" name="dateTo" ref="dateTo"
              v-model="filter.dateTo" @input="changeDateTo" :v="$v.filter.dateTo" placeholder="DD/MM/YYYY"
              class="mb-2" />
          </b-col>
          <b-col>
            <b-form-group label-class="p-0" class="mb-2">
              <template #label>
                <b class="f-size-14">เดือนเกิด (Birth Month)</b>
              </template>
              <b-dropdown no-caret ref="dropdown" class="w-100 m-0" right variant="dropdown-customs" block>
                <template #button-content>
                  <div class="d-flex justify-content-between align-items-center">
                    เดือนเกิด ({{ filter.birthdayMonth.length }})
                    <font-awesome-icon class="arrow" icon="chevron-down" />
                  </div>
                </template>
                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox class="f-size-14 w-33 pr-1 mr-0" v-model="birthdayMonthOptions"
                    aria-describedby="birthdayMonth" aria-controls="birthdayMonth" @change="birthdayMonthToggle">
                    ทั้งหมด
                  </b-form-checkbox>
                  <b-form-checkbox-group v-model="filter.birthdayMonth" :aria-describedby="ariaDescribedby"
                    class="f-size-14" stacked>
                    <b-form-checkbox class="w-33 pr-1 mr-0" :value="opt.id" :id="opt.name" v-for="opt of monthList"
                      :key="opt.id">{{ opt.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </b-dropdown>
            </b-form-group>
          </b-col>
          <b-col>
            <InputSelect title="สาขาที่ซื้อล่าสุด (Last Purchase Branch)" isRequired :options="branchList"
              v-model="filter.branchId" @onDataChange="(val) => (filter.branchId = val)" textField="name"
              valueField="id" class="mb-2" />
          </b-col>
          <b-col>
            <InputSelect title="เรียงลำดับ (Sort By)" isRequired :options="sortByList" v-model="filter.sortBy"
              @onDataChange="(val) => (filter.sortBy = val)" textField="name" valueField="id" class="mb-2" />
          </b-col>
        </template>
      </HeaderPanel>
      <div class="bg-white border-red mt-3">
        <b-row>
          <b-col>
            <b-table responsive striped hover :fields="fields" :items="items" :busy="isBusy" show-empty
              empty-text="No matching records found" class="table-main" :no-border-collapse="false">
              <template v-slot:cell(member_id)="data">
                <router-link :to="{
                  path: '/report/ref-sale-member',
                  query: {
                    telephone: data.item.telephone,
                    memberId: data.item.member_id,
                    memberName: data.item.first_name_th,
                  },
                }">
                  <u> {{ data.item.member_id }}</u>
                </router-link>
              </template>
              <template v-slot:cell(first_name_th)="data">
                <p>{{ data.item.first_name_th || "-" }}</p>
                <p>{{ data.item.first_name_en || "-" }}</p>
              </template>
              <template v-slot:cell(last_name_th)="data">
                <p>{{ data.item.last_name_th || "-" }}</p>
                <p>{{ data.item.last_name_en || "-" }}</p>
              </template>
              <template v-slot:cell(birth_month)="data">
                {{ months[data.item.birth_month] || "-" }}
              </template>
              <template v-slot:cell(branch_spend)="data">
                <div>{{ data.item.branch_spend | numeral("0,0.00") }}</div>
              </template>
              <template v-slot:cell(other_spend)="data">
                <div>{{ data.item.other_spend | numeral("0,0.00") }}</div>
              </template>
              <template v-slot:cell(point)="data">
                <div>{{ data.item.point | numeral("0,0") }}</div>
              </template>

              <template v-slot:cell(center_point)="data">
                <div>{{ data.item.center_point | numeral("0,0") }}</div>
              </template>
              <template v-slot:cell(register_date)="data">
                <template v-if="data.item.register_date">
                  <div>
                  {{
                    $moment(data.item.register_date)
                      .add(543, "years")
                      .format("DD/MM/YY")
                  }}
                  <br>
                  {{
                    $moment(data.item.register_date)
                      .add(543, "years")
                      .format("HH:mm:ss")
                  }}
                </div>
                </template>
                <template v-else>-</template>
              </template>
              <template v-slot:cell(is_consent)="data">
                <div v-if="data.item.is_consent">ยินยอม</div>
                <div v-else>รอยินยอม</div>
              </template>
              <template v-slot:cell(action)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <img src="@/assets/images/icons/note.png" alt="transfer" class="action-img pointer" width="20" center
                    @click="$refs.modalNote.show(data.item)" />
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <Pagination @handleChangeTake="handleChangeTake" :pageOptions="pageOptions" :filter="filter" :rows="rows"
          @pagination="pagination" />
      </div>
    </div>
    <ModalNote ref="modalNote" :branchId="branchId" />
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import ModalNote from "@/components/customer/ModalNote";
import InputDatePickerFilter from "@/components/inputs/InputDatePickerFilter";
import { mapGetters } from "vuex";
import { minValue, maxValue } from "vuelidate/lib/validators";

const minDate = (value, fromDate) => {
  if (!value) return true;
  let minDate = fromDate;
  return new Date(value) >= new Date(minDate);
};
const maxDate = (value, toDate = null) => {
  let maxDate = toDate || new Date();
  return new Date(maxDate) >= new Date(value);
};

export default {
  components: {
    OtherLoading,
    Pagination,
    HeaderPanel,
    InputSelect,
    InputText,
    ModalNote,
    InputDatePickerFilter,
  },
  name: "Customer",
  data() {
    return {
      fields: [
        {
          key: "member_id",
          label: "รหัสสมาชิก",
          thStyle: { "min-width": "175px" },
        },
        {
          key: "first_name_th",
          label: "ชื่อ",
          thStyle: { "min-width": "120px" },
        },
        {
          key: "last_name_th",
          label: "นามสกุล",
          thStyle: { "min-width": "120px" },
        },
        {
          key: "telephone",
          label: "เบอร์โทร",
        },
        {
          key: "email",
          label: "อีเมล",
          thStyle: { "min-width": "120px" },

        },
        {
          key: "birth_month",
          label: "เดือนเกิด",
          thStyle: { "min-width": "100px" },
        },
        {
          key: "register_date",
          label: "วันที่สมัคร",
        },
        {
          key: "is_consent",
          label: "Consent status",
        },
        {
          key: "action",
          label: "",
          tdClass: "text-center",
        },
      ],
      items: [],
      isBusy: false,
      isLoadingData: false,
      rows: 0,
      filter: {
        branchId: 0,
        page: 1,
        take: 10,
        telephone: "",
        name: "",
        frequency: null,
        gender: null,
        point: null,
        birthdayMonth: [],
        newRegisterMonth: null,
        currentSpending: null,
        otherSpending: null,
        returnMonth: null,
        noReturnMonth: null,
        sortBy: "1",
        centerPoint: null,
        isProspect: null,
        productType: 0,
        notReturnDays: null,
        lastPurchaseBranchId: 0,
        dateFrom: null,
        dateTo: null,
        gender: "0",
      },
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      months: [
        "-",
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      selected: 0,
      isLoading: true,
      branchList: [],
      monthList: [],
      genderList: [],
      sortByList: [],
      productTypeList: [
        { value: 0, text: "ทั้งหมด" },
        { value: 1, text: "สินค้า" },
        { value: 2, text: "บริการสปา" },
      ],
      today: null,
      birthdayMonthOptions: false,
    };
  },
  validations: {
    filter: {
      dateFrom: {
        maxValue(val, { dateTo }) {
          return maxDate(val, dateTo);
        },
      },
      dateTo: {
        minValue(val, { dateFrom }) {
          return minDate(val, dateFrom);
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      branchId: "getBranchId",
    }),
  },
  watch: {
    "filter.birthdayMonth"(newValue) {
      if (newValue.length === 0) {
        this.birthdayMonthOptions = false;
      } else if (newValue.length === this.monthList.length) {
        this.birthdayMonthOptions = true;
      } else {
        this.birthdayMonthOptions = false;
      }
    },
  },
  created: async function () {
    this.today = this.$moment().format("YYYY-MM-DDT00:00:00");
    await this.getMonthList();
    await this.birthdayMonthToggle(true);
    this.getBranchList();
    this.getGenderList();
    this.getSortByList();
    await this.getList();
  },
  methods: {
    getList: async function () {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) return;

      this.isBusy = true;
      let payload = { ...this.filter };
      payload.skip = (payload.page - 1) * payload.take;

      payload.branch_id = payload.branchId;
      payload.birthday_month = payload.birthdayMonth.toString();
      payload.new_register_month = payload.newRegisterMonth;
      payload.current_spending = payload.currentSpending;
      payload.other_spending = payload.otherSpending;
      payload.return_month = payload.returnMonth;
      payload.no_return_month = payload.noReturnMonth;
      payload.sort_by = payload.sortBy;
      payload.center_point = payload.centerPoint;
      payload.is_prospect = payload.isProspect;
      payload.product_type = payload.productType;
      payload.not_return_days = payload.notReturnDays;
      payload.last_purchase_branch_id = payload.lastPurchaseBranchId;
      payload.date_from = payload.dateFrom;
      payload.date_to = payload.dateTo;

      this.isLoadingData = true;
      await this.axios
        .post(`${this.$baseUrl}/customer/prospect`, payload)
        .then((data) => {
          this.isLoading = false;
          if (data.result == 1) {
            this.items = data.detail;
            this.rows = data.total_count;
            this.isLoadingData = false;
          }
        });

      this.isBusy = false;
    },
    getBranchList: async function () {
      let list = [{ id: 0, name: "ทั้งหมด" }];

      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_branch_list`)
        .then((data) => {
          if (data.result == 1) {
            this.branchList = list.concat(data.detail);
          }
        });
    },
    getMonthList: async function () {
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_month_list`)
        .then((data) => {
          if (data.result == 1) {
            let filtered = data.detail.filter((item) => item.id != "0");
            this.monthList = filtered;
          }
        });
    },
    getGenderList: async function () {
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_gender`)
        .then((data) => {
          if (data.result == 1) {
            this.genderList = data.detail;
          }
        });
    },
    getSortByList: async function () {
      await this.axios
        .get(`${this.$baseUrl}/dropdown/get_sortby_list`)
        .then((data) => {
          if (data.result == 1) {
            this.sortByList = data.detail;
          }
        });
    },
    handleSearch(value) {
      this.pagination(1);
    },
    pagination(page) {
      this.filter.page = page;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.take = value;
      this.$emit("filterPage", this.filter);
      this.getList();
    },
    async clearFilter() {
      this.$v.filter.$reset();

      this.filter = {
        branchId: 0,
        page: 1,
        take: 10,
        telephone: "",
        name: "",
        frequency: null,
        gender: null,
        point: null,
        birthdayMonth: [],
        newRegisterMonth: null,
        currentSpending: null,
        otherSpending: null,
        returnMonth: null,
        noReturnMonth: null,
        sortBy: "1",
        centerPoint: null,
        isProspect: null,
        productType: 0,
        notReturnDays: null,
        lastPurchaseBranchId: 0,
        dateFrom: null,
        dateTo: null,
        gender: "0",
      };
      await this.birthdayMonthToggle(true);
      await this.getList();
    },
    changeDateFrom(val) {
      this.filter.dateFrom = val;
    },
    changeDateTo(val) {
      this.filter.toDate = val;
    },
    birthdayMonthToggle(checked) {
      this.filter.birthdayMonth = checked
        ? this.monthList.map((el) => el.id)
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.time {
  color: #afafaf;
}

.line-clamp2 {
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.badge {
  font-size: 85%;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  max-width: max-content;
  font-weight: 100;
}

.badge-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

::v-deep .arrow {
  font-size: 12px;
  color: #333 !important;
}

::v-deep .btn-dropdown-customs {
  text-align: left !important;
  border: 1px solid #bcbcbc !important;
  font-size: 14px !important;
  padding: 8px 10px !important;
  border-radius: 5px !important;
}
</style>
